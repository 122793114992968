// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

// Reload the page every minute to see if maintenance mode has been lifted
window.addEventListener("load", (event) => {
  window.setTimeout( function() {
    window.location.reload();
  }, 60000);
});
